import SbEditable from "storyblok-react"
import dynamic from "next/dynamic"

// resolve Storyblok components to Next.js components
export const Components = {
  // Atoms
  button: dynamic(() => import("./atoms/StoryblokButton")),
  divider: dynamic(() => import("./atoms/Divider")),
  logo_divider: dynamic(() => import("./atoms/LogoDivider")),
  inline_video: dynamic(() => import("./atoms/InlineVideo")),
  page_header: dynamic(() => import("./molecules/PageHeader")),
  pull_quote: dynamic(() => import("./atoms/PullQuote")),
  dual_button: dynamic(() => import("./atoms/DualButton")),
  two_column: dynamic(() => import("./atoms/TwoColumn")),
  icon_card: dynamic(() => import("./atoms/IconCard")),
  audio_embed: dynamic(() => import("./atoms/AudioEmbed")),
  pdf_download: dynamic(() => import("./atoms/PDFDownload")),
  newsletter_sign_up_form: dynamic(() => import("./atoms/NewsletterSignUpForm")),
  inline_iframe: dynamic(() => import("./atoms/InlineIframe")),
  award_card: dynamic(() => import("./atoms/AwardCard")),
  frequently_asked_question: dynamic(() => import("./atoms/FAQ")),
  stars_review: dynamic(() => import("./atoms/Stars")),
  badge: dynamic(() => import("./atoms/Badge")),
  multi_badge: dynamic(() => import("./atoms/MultiBadge")),

  // Molecules
  divider_section: dynamic(() => import("./atoms/Divider")),
  cta_banner: dynamic(() => import("./molecules/CTABanner")),
  bracketed_section: dynamic(() => import("./molecules/BracketedSection")),
  divider_image_and_content: dynamic(() => import("./molecules/DividerImageAndContent")),
  image_and_content: dynamic(() => import("./molecules/ImageAndContent")),
  logo_carousel_section: dynamic(() => import("./molecules/LogoCarouselSection")),
  press_articles_section: dynamic(() => import("./molecules/PressArticlesSection")),
  inline_image_gallery: dynamic(() => import("./molecules/InlineImageGallery")),
  scrolling_section: dynamic(() => import("./molecules/ScrollingSection")),
  home_hero: dynamic(() => import("./molecules/HomeHero")),
  inline_cta_banner: dynamic(() => import("./molecules/InlineCTABanner")),
  sidebar_form_section: dynamic(() => import("./molecules/SidebarFormSection")),
  team_qa_section: dynamic(() => import("./molecules/Q&ASection")),
  featured_team_section: dynamic(() => import("./molecules/FeaturedTeamMember")),
  heading_text_divider_section: dynamic(() => import("./molecules/HeadingTextDividerSection")),
  icon_grid: dynamic(() => import("./molecules/IconGrid")),
  three_column_section: dynamic(() => import("./molecules/ThreeColumnSection")),
  awards_and_memberships_grid: dynamic(() => import("./molecules/AwardsAndMembershipsGrid")),
  video_and_content: dynamic(() => import("./molecules/VideoAndContent")),
  card_and_content: dynamic(() => import("./molecules/CardAndContent")),
  featured_case_wins: dynamic(() => import("./molecules/FeaturedCaseWins")),
  in_the_community_category_archive: dynamic(() => import("./molecules/InYourCommunityCategoryArchive")),
  content_and_card_section: dynamic(() => import("./molecules/ContentAndCardSection")),
  fee_icon_section: dynamic(() => import("./molecules/FeeIconSection")),
  three_stat_box_section: dynamic(() => import("./molecules/ThreeStatBoxSection")),
  form_embed_section: dynamic(() => import("./molecules/FormEmbedSection")),
  our_difference_graphic: dynamic(() => import("./molecules/OurDifferenceGraphic")),

  // Organisms
  page: dynamic(() => import("./organisms/Page")),
}

const DynamicComponent = ({ blok }) => {
  // check if component is defined above
  if (typeof Components[blok?.component] !== "undefined") {
    const Component = Components[blok.component]

    // Set an _editable value to avoid error in SbEditable
    if (blok._editable === null) {
      blok._editable = undefined
    }
    if (typeof window !== "undefined" && window.location.search.includes("_storyblok")) {
      return (
        <SbEditable content={blok}>
          <Component blok={blok} />
        </SbEditable>
      )
    }

    return <Component blok={blok} />
  }

  // fallback if the component doesn't exist
  return null
}

export default DynamicComponent
